<template lang="pug">
div(v-if='Citas')
    .lineamedia-pc.d-none.d-md-flex
        v-img(:src='Citas.calendario'  @click='redirigir_a(Citas.enlace)' contain ).imagen_pc.pointer
        p(v-html="Citas.agenda"  ).d-none.d-md-block.white--text.texto


            
</template>
<script>
export default {
        methods: {
            redirigir_a(enlace){
                window.open(enlace, '_blank')
            },
        },
    }
</script>
<style lang="sass" scoped>
.texto
    position: relative
    top:30%
    right: -30%
    width: 340px
    height: 70px
 

    line-height: 0.3em
.pointer
    cursor: pointer
.imagen_pc
    position: fixed
    right: 3em
   
    height: 65px
    z-index: 10000


.lineamedia-pc
    position: fixed
    top: 80%
    right: -9%
    width: 320px
    height: 70px
    z-index: 1
    border-radius: 25px
    margin-top: 5px
    border-radius: 30px 0 0 30px
    height:70px
  
    margin-bottom: 2px
    background-color: #00AA7F !important

</style>